<!-- @format -->

<template>
	<div class="policy">
		<v-container class="mx-auto py-0">
			<p class="text-h4 text-center font-weight-bold text-uppercase">Chính Sách</p>

			<v-card class="py-2 px-3">
				<p class="text-h5 font-weight-bold">
					Chính sách tham gia tài khoản của các thành viên:
				</p>

				<p class="text-body-1">
					👉 1. Về tiêu chí hoạt động, Doonkee chỉ là một trang web cá nhân hỗ trợ thành viên đăng ký tài khoản, tìm người dùng chung để sử dụng các nền tảng giải trí, học tập
				</p>

				<p class="text-body-1">
					<span style="display: inline-block; width: 2ch;">&#9;</span>
					⭐ Khi thành viên truy cập vào website của chúng tôi có nghĩa là thành viên đồng ý với các điều khoản này. Trang web có quyền thay đổi, chỉnh sửa, thêm hoặc lược bỏ bất kỳ phần nào
					trong Điều khoản này, vào bất cứ lúc nào. Các thay đổi có hiệu lực ngay khi được đăng trên trang web mà không cần thông báo trước. Và khi thành viên tiếp tục sử dụng trang web, sau
					khi các thay đổi về Điều khoản này được đăng tải, có nghĩa là thành viên chấp nhận với những thay đổi đó.
				</p>

				<p class="text-body-1">
					<span style="display: inline-block; width: 2ch;">&#9;</span>
					⭐ Thành viên vui lòng kiểm tra thường xuyên để cập nhật những thay đổi của chúng tôi.
				</p>

				<p class="text-body-1">
					<span style="display: inline-block; width: 2ch;">&#9;</span>
					⭐ Doonkee không phải là đại lý ủy quyền phân phối cũng như không phải là đại diện cho các công ty cung cấp các nền tảng giải trí, học tập do đó Doonkee không kinh doanh để thu lợi
					nhuận từ các nội dung và không chịu trách nhiệm về nội dung trên các nền tảng, Doonkee chỉ có trách nhiệm hỗ trợ ghép nhóm và xử lý các sự cố phát sinh trong quá trình sử dụng
					trong khả năng của mình. Các thành viên đăng ký ghép nhóm và sử dụng trên tinh thần tự nguyện, cam kết ghép nhóm để phục vụ mục đích sử dụng cá nhân, không sử dụng vào các mục
					đích vi phạm pháp luật. Đồng ý cùng Doonkee chia sẻ và giải quyết các vấn đề khi có khiếu nại. Doonkee luôn khuyến khích thành viên tự đăng ký thuê bao trực tiếp từ các nền tảng để
					được bảo đảm quyền lợi cũng như được hỗ trợ trực tiếp.
				</p>

				<p class="text-body-1">
					<span style="display: inline-block; width: 2ch;">&#9;</span>
					⭐ Số tiền phí các thành viên chuyển cho Doonkee sẽ được dùng để thanh toán duy trì cho chi phí các gói thuê bao của các nền tảng và chi phí để liên lạc cho các thành viên cũng như
					duy trì các tài nguyên phục vụ việc kết nối liên lạc các thành viên trong nhóm, khắc phục sự cố trong quá trình sử dụng.
				</p>

				<p class="text-body-1">
					👉 2. Mọi thắc mắc, khiếu nại được tiếp nhận thông qua fanpage, trang cá nhân hoặc zalo. Thông tin sẽ được tiếp nhận và xử lý tối đa trong vòng 7 ngày. Doonkee cam kết thực hiện
					đúng chính sách bảo hành và nói không với lừa đảo, do đó nghiêm cấm tình trạng dùng tài khoản giả mạo để vu khống làm ảnh hưởng danh dự của Doonkee cũng như các thành viên khác khi
					chưa đủ căn cứ.
				</p>

				<p class="text-body-1">
					<span style="display: inline-block; width: 2ch;">&#9;</span>
					⭐ Trường hợp bất khả kháng (fanpage, trang cá nhân bị vô hiệu hóa ....) các thành viên không nhầm lẫn với việc bị chặn, Doonkee sẽ có thông tin liên lạc bổ sung ở trang chủ trong
					thời gian sớm nhất.
				</p>

				<p class="text-body-1">
					👉 3. Các thành viên lựa chọn sử dụng profile ở Doonkee chủ động tìm hiểu về ưu và nhược điểm khi dùng profile share và cam kết thực hiện quy định của từng nhóm.
				</p>
			</v-card>

			<v-card class="mt-3 py-2 px-3">
				<p class="text-h5 font-weight-bold">
					Chính sách bảo mật cho các thành viên:
				</p>

				<p class="text-body-1">
					👉 Mọi thông tin thành viên cung cấp phục vụ cho việc thông báo về tình trạng tài khoản cũng như để liên lạc khi có sự cố xảy ra. Doonkee cam kết không cung cấp cho bên thứ 3.
				</p>

				<p class="text-body-1">
					👉 Doonkee đảm bảo việc người chia sẻ tài khoản và người đóng góp thực hiện đúng cam kết, không bị lừa đảo và "mất tiền oan".
				</p>

				<p class="text-body-1">
					👉 Trong trường hợp tài khoản có sự cố thành viên chia sẻ có nghĩa vụ xử lý hoặc cấp một tài khoản khác để nhóm tiếp tục cùng nhau sử dụng. Trường hợp xấu nhất không thể cấp được
					tài khoản thay thế, thành viên đóng góp sẽ được hoàn tiền đúng bằng số ngày chưa sử dụng. Ví dụ:
				</p>

				<p class="text-body-1">
					<span style="display: inline-block; width: 2ch;">&#9;</span>
					⭐ Đối với thành viên sử dụng <b>Tài khoản xem phim</b>. Thành viên đăng ký sử dụng 3 tháng ( tương đương 90 ngày) với phí đóng góp là 180.000đ. Tuy nhiên sau khi sử dụng được 80
					ngày tài khoản bị lỗi mà người chia sẻ không thể xử lý cũng như cấp tài khoản thay thế, nghĩa là còn 10 ngày chưa sử dụng thì thành viên sẽ được hoàn tiền từ nguồn tiền tạm giữ
					theo công thức:
				</p>

				<p class="text-center font-weight-bold text-sm-h6">Số tiền hoàn lại = 180.000 / 90 x 10 = 20.000đ.</p>

				<p class="text-body-1">
					<span style="display: inline-block; width: 2ch;">&#9;</span>
					⭐ Phần còn lại Doonkee sẽ chuyển trả cho thành viên chia sẻ.
				</p>

				<p class="text-body-1">
					<span style="display: inline-block; width: 2ch;">&#9;</span>
					⭐ Tương tự đối với thành viên sử dụng chung các nền tảng khác.
				</p>
			</v-card>
		</v-container>
	</div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Policy extends Vue {}
</script>

<style></style>
